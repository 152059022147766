import React, {Component} from "react";
import {Box, Heading} from "grommet";
import {GroupableTable} from "./content";
import {Details} from "./details/description/";


export class Peptides extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }







    render() {
       let url = require(".././data/url");
        const {data} = this.props;




        return (
            <Box fill align="start" justify="center">
                <Heading>Peptides</Heading>



                <GroupableTable

                    data={data}

                    columns={
                        [
                            {title: 'ID', field: 'sequence_id',

                                render: rowData => rowData.sequence_id,
                                defaultSort: "asc"},
                            {title: 'Name', field: "name"},
                            {title: 'Sequence', field: "sequence"},
                            {title: 'Source', field: 'source'},
                            {title: 'Activity', field: 'activity'},
                            {title: 'Gram', field: 'gram'},


                        ]
                    }

                    detailPanel={[
                        {

                            tooltip: 'Details',
                            render: rowData => {


                                return (
                                    <Details heading={rowData.name}
                                             id={rowData.sequence_id}
                                             source={rowData.source}
                                             activity={rowData.activity}
                                             gram={rowData.gram}
                                             target={rowData.target}
                                             sequence={rowData.sequence}

                                             apd3={rowData.apd3_id}
                                             bactibase={rowData.bactibase_id}
                                             camp={rowData.camp_id}
                                             dramp={rowData.DRAMP_id}
                                             dbamp={rowData.dbamp_id}
                                             dbaasp={rowData.dbaasp_id}
                                             lamp2={rowData.lamp2_id}
                                             uniprot={rowData.uniprot_id}
                                             yadamp={rowData.yadamp_id}




                                             datas={rowData}


                                             url={url.drugDetails + rowData.id}></Details>

                                )
                            }
                        },

                    ]}></GroupableTable>
                <span>&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;</span>
            </Box>


        );
    }
}
