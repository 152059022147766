import React, {Component, forwardRef} from "react";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import MaterialTable from "material-table";
import "./GroupableTable.css";

const tableIcons = {
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
};


export class DetailTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: null
        }
    }

    render() {

        //const newData=data.matchID.includes(id);
        const {source,activity,gram,target,sequence,apd3, bactibase, camp, dramp, dbamp, dbaasp,lamp2,uniprot,yadamp} = this.props;
        return  <MaterialTable

            icons={tableIcons}

                columns={[
                    { title: 'Sequence', field: 'sequence' },
                    { title: 'Source', field: 'source' },
                    { title: 'Activity', field: 'activity' },
                    { title: 'Gram', field: 'gram' },
                    { title: 'Target', field: 'target' },



                    {title: 'APD3',field:'apd3'},
                    {title: 'Bactibase',field:'bactibase'},
                    {title: 'DRAMP',field:'dramp'},
                    {title: 'dpAMP',field:'dbamp'},
                    {title: 'DBAASP',field:'dbaasp'},
                    {title:'LAMP2', field:'lamp2'},
                    {title: 'Uniprot', field:'uniprot'},
                    {title:'YADAMP', field:'yadamp'}

                    ,


                ]}
                data={[
                    { sequence:  sequence, source: source, Activity: activity, Gram:gram, Target: target,  apd3 : apd3, bactibase: bactibase, camp: camp, dramp: dramp, dbamp: dbamp, dbaasp:dbaasp,lamp2:lamp2,uniprot:uniprot,yadamp:yadamp},

                ]}


            options={{
                tableLayout: "fixed",

                cellStyle: {
                     cellWidth: '100%',
                     wordBreak: "break-word",
                     whiteSpace: "normal",
                     minWidth: '100%',

                     maxWidth: 300,
                 },

                pageSizeOptions: 1,
                search:false,
                pageSize: 20,
                showTitle: false,
                paging:false,




            }}



            >
        </MaterialTable>;

    }

}

