import React from "react";
import { Box, Paragraph} from "grommet";
import corona2 from "../images/logo5.jpg"
import './NotFound.css';


export const NotFound = () => (
  <Box fill align="center" pad={{ top: "large", horizontal: "small" }}>
      <Box style={{ flexDirection: 'row', justifyContent:'space-between',padding:"large"}}>


          <img src={corona2?.default || corona2} className="nf" alt="" />




      </Box>


    <Paragraph textAlign="center" >
        The page you requested does not exist or has been removed.
    </Paragraph>
  </Box>
);
