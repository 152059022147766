import React, {Component} from "react";
import {Database} from "../icons";
import "./About.css"
import {Box, Text} from "grommet";
import heider from "../images/Logo_HeiderLab-alpha.png";
import signals from "../images/logo_loewe_ds.jpg";
import imi from "../images/Logo_IMI.png";
import muenster from "../images/Logo_UniMuenster.png";
import Divider from "@material-ui/core/Divider";


const size = 20;
const brand = "#82b5e4";


export class About extends Component {




    render() {
        //const {intData} = this.state;
        return (

            <Box>


                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
                <Box pad={"large"}>

                    <Text style={{fontSize: 48, fontWeight: "bold"}}>
                        COMPASS Collection Of antiMicrobial Peptide dAta baSeS
                        {"\n"} </Text>
                </Box>
                <span>&nbsp;&nbsp;</span>
{/*                <Divider/>
                <Box pad={"large"}>
                    <Text style={{fontSize: 36}}>
                        Currently under maintenance. Some functions may be temporarily unavailable. {"\n"}
                    </Text>
                </Box>
                <span>&nbsp;&nbsp;</span>
                <Divider/>*/}

                <span>&nbsp;&nbsp;</span>
                <Box>
                    <Text style={{fontSize: size}}>
                        COMPASS is a database, which collects and aggregates information of antimicrobial peptides (AMPs) from:{"\n"}
                        {"\n"}
                    </Text>
                </Box>
                <Box className="List" style={{fontSize: size, paddingTop: 20, paddingLeft: 80, paddingBottom: 20}}>

                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://aps.unmc.edu/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Database/> APD3</a>
                        {"\n"}</Text>

                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a
                        href="http://bactibase.pfba-lab-tun.org"
                        rel="noopener noreferrer"
                        target="_blank"><Database/> Bactibase</a>
                        {"\n"} </Text>
                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="http://www.camp3.bicnirrh.res.in/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Database/> CAMP3</a>
                        {"\n"} </Text>
                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="http://dramp.cpu-bioinfor.org/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Database/> DRAMP</a>
                        {"\n"} </Text>
                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://awi.cuhk.edu.cn/dbAMP/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Database/> dbAMP 2.0</a>
                        {"\n"} </Text>
                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://dbaasp.org/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Database/> DBAASP v3.0</a>
                        {"\n"} </Text>

                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="http://biotechlab.fudan.edu.cn/database/lamp/index.php"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Database/> LAMP2</a>
                        {"\n"} </Text>

                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://www.uniprot.org/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Database/> Uniprot</a>
                        {"\n"} </Text>

                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="http://yadamp.unisa.it"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Database/> YADAMP</a>
                        {"\n"} </Text>


                    {"\n"}

                </Box>

                <Text>
                    The information provided is for research only and we cannot guarantee the correctness of the
                    data.{"\n"}
                </Text>


                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
                {/* <Box>
                    Please cite: <a href="https://www.cell.com/iscience/fulltext/S2589-0042(20)30484-3"
                                    rel="noopener noreferrer"
                                    target="_blank">  PAPER</a>


                </Box>*/}

                <span>&nbsp;&nbsp;</span>
                <Divider/>
                <span>&nbsp;&nbsp;</span>
                <Box direction={"row"}>
                    <Box> <img src={signals} className="logo2" alt=""/></Box>
                    <Box> <img src={heider} className="logo2" alt=""/></Box>
                    <Box> <img src={muenster} className="logo2" alt=""/></Box>
                    <Box> <img src={imi} className="logo2" alt=""/></Box>
                </Box>
                <span>&nbsp;&nbsp;</span>
            </Box>


        );
    }
}
