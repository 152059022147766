import React, {Component} from "react";
import "./About.css"
import {Box, Heading, Text} from "grommet";
import Divider from "@material-ui/core/Divider";
import {Link} from "react-router-dom";
import l1 from "../images/length_distribution.png";






export class AMPGPT extends Component {




    render() {

        return (

            <Box>

                <Heading>AmpGPT2</Heading>

                <span>&nbsp;&nbsp;</span>


                <span>&nbsp;&nbsp;</span>


                <span>&nbsp;&nbsp;</span>
                <Text>
                    AmpGPT2 is a model designed for AMP discovery.
                    It is a fine-tuned version of <a href="https://www.nature.com/articles/s41467-022-32007-7"
                                                          rel="noopener noreferrer"
                                                          target="_blank"> ProtGPT2</a>, trained on the data provided by COMPASS.
                  The model's performance was validated using <a
                    href="https://www.dveltri.com/ascan/v2/ascan.html"
                    rel="noopener noreferrer"
                    target="_blank"> AMP
                    Scanner
                    vr.2</a>.
                </Text>
                <span>&nbsp;&nbsp;</span>
                <Text>
                    The training data can be downloaded <Link to="/data.json" target="_blank"
                                                                         download>here</Link>{"\n"}.
                </Text>


                <span>&nbsp;&nbsp;</span>
                <Text>
                    The AmpGPT2 model can be downloaded <a href="https://imigitlab.uni-muenster.de/heiderlab/AmpGPT2"
                                                             rel="noopener noreferrer"
                                                             target="_blank"> here</a>.
                </Text>
                <span>&nbsp;&nbsp;</span>
                <h3>
                    Comparison to ProtGPT2
                </h3>


                <Text>
                    AmpGPT2 generates 95% potential AMPs, compared to 55% for ProtGPT2, while producing shorter
                    sequences on average.
                    length_distribution.png
                </Text>

                <Box direction={"column"}>
                    <img src={l1?.default || l1} className="logo2" alt="" />
                </Box>
                <span>&nbsp;&nbsp;</span>
                <Divider/>
                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
            </Box>


        );
    }
}
