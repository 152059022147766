import React, {Component} from "react";
import {Box, Text} from "grommet";
import "./Details.css"
import {DetailTable} from "../../content";
import url from "../../../data/url.json";


export class Details extends Component {





    render() {



        const {heading,source,activity,gram,target,sequence,apd3, bactibase, camp, dramp, dbamp, dbaasp,lamp2,uniprot,yadamp} = this.props;




        return (
            <Box align={"start"} fill={"horizontal"}>

            <span>&nbsp;&nbsp;</span>

                <Box fill={"horizontal"}>



                        <Box fill align="start" justify="center" pad="small">

                        <Text style={{fontSize: 48, color: "#83b4df"}}>
                                {heading}
                            </Text>
                        </Box>
                        <span>&nbsp;&nbsp;</span>


               <Box
                   pad={"medium"} fill={"horizontal"}

               >
                        <span>&nbsp;&nbsp;</span>
                        <DetailTable


                            source={source}
                            activity={activity}
                            gram={gram}
                            target={target}
                            sequence={sequence}

                            apd3={apd3}
                            bactibase={bactibase}
                            camp={camp}
                            dramp={dramp}
                            dbamp={dbamp}
                            dbaasp={dbaasp}
                            lamp2={lamp2}
                            uniprot={uniprot}
                            yadamp={yadamp}

                            url={url}


                        ></DetailTable>
               </Box>

                        <span>&nbsp;&nbsp;</span>


                </Box>


            </Box>

        );
    }
}
