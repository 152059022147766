import React, {Component} from "react";
import {render} from "react-dom";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import {theme} from "./theme";
//import "./Design.css"
import { NotFound, About, Peptides, AMPGPT} from "./pages";
import {Box, Grid, Grommet, Main, Text} from 'grommet';
import Container from '@material-ui/core/Container';
import Divider from "@material-ui/core/Divider";
import Navigation from "./components/Navigation";
import {LoadingBar} from "./LoadingBar";



const items = [

    {
        name: "  About",
        pathto: "/",
        value: 1,
    },
    {
        name: "  Peptides",
        pathto: "/Peptides",
        value: 2,
    },

    {
        name: "  AmpGPT2",
        pathto: "/ampgpt",
        value: 5,
    }

];


class App extends Component {

  constructor(props) {
          super(props);
          this.state = {

              peptidesData: [],

              loading: true
          };
      }

      //state = { loading: true };

      componentDidMount() {
          let url = require("./data/url");
          Promise.all([

              fetch(url.peptides),


          ])
              .then(([res1]) => Promise.all([res1.json()]))
              .then(([peptidesData]) => this.setState({

                  peptidesData: peptidesData.data,

                  loading:false,
              })).catch(error => console.log(`Error in promises ${error}`))
      }

    render() {

        const {peptidesData, loading} = this.state;


        return (

            <Grommet theme={theme} full>
                <Grid fill rows={["auto", "flex", "auto"]}>
                    {/*<Header/>*/}

                    <Divider/>
                    <Box>

                        <Grommet theme={theme} full>
                            <Grid fill rows={["auto", "flex", "auto"]}>


                                <BrowserRouter>

                                    <Box  direction="row" full justify={"start"} align="start"
                                         background={"white"}>



                                        <Navigation items={items}/>

                                    </Box>
                                    {/* <Divider/>
                                            <Box pad="medium">*/}
                                    <Main>
                                        {loading ? <LoadingBar/> :
                                            <Container maxWidth={"xl"}>

                                                <Switch>

                                                    <Route path="/" exact component={About}/>



                                                    <Route path="/Peptides"> 
                                                              < Peptides
                                                                  data={peptidesData}
                                                              />
                                                          </Route>

                                                    <Route path="/ampgpt">
                                                        <AMPGPT/>
                                                    </Route>


                                                    <Route component={NotFound}/>

                                                </Switch>

                                            </Container>}
                                    </Main>
                                </BrowserRouter>

                            </Grid>

                        </Grommet>


                    </Box>




                    <Divider></Divider>
                    <Box tag="footer" pad="xxsmall"  color="#e9e6df" background={"153, 198, 97,0.5"}>
                        <Box style={{flex: 1,flexDirection: 'row', justifyContent: 'space-between'}}>


                            <Text style={{color: "#000000"}}>{"\t"}
                                <a href="https://www.medizin.uni-muenster.de/imi/impressum.html "
                                   rel="noopener noreferrer"
                                   target="_blank"> Disclosure</a>


                            </Text>

                            <Text style={{color: "#000000"}}>{"\t"} <a href="https://www.medizin.uni-muenster.de/fakultaet/datenschutz.html"
                                                                       rel="noopener noreferrer"
                                                                       target="_blank"> Privacy Statement</a>
                            </Text>


                            <Text color="#000000" style={{paddingRight: 10}}> v1.0.0 </Text>
                        </Box>
                    </Box>

                </Grid>
            </Grommet>
        );
    }
}

render(<App/>, document.getElementById("root"));

